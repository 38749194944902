

export const routers = [
    { 
    id: 2,
    name: "Rafael-Casa",
    ip: "192.168.45.200",
    port: 2033,
    iptv: "no",
    hardwareVersion: "Mikrotik",
    softwareVersion: "v7",
    },
    {
    id: 3,
    name: "Prisma-Escritório",
    ip: "192.168.0.200",
    port: 20311,
    iptv: "no",
    hardwareVersion: "Mikrotik",
    softwareVersion: "v7",
    },
    {
        id: 4,
        name: "Prisma-BGP",
        ip: "45.70.124.51",
        port: 20311,
        iptv: "no",
        hardwareVersion: "Mikrotik-CCR1036",
        softwareVersion: "v6.34",
    },
    {
        id: 5,
        name: "Ensata-VxLAN",
        ip: "190.83.100.131",
        port: 2022,
        iptv: "no",
        hardwareVersion: "Mikrotik-CCR1036",
        softwareVersion: "v7.14.3",
    },
    {
        id: 6,
        name: "Ensata-BGP",
        ip: "190.83.100.0",
        port: 22,
        iptv: "no",
        hardwareVersion: "Mikrotik-CCR1036",
        softwareVersion: "v7.14.3",
    },
]