import { useState } from "react"
import "./Pesquisa.css"
import { routers } from '../dados/routers'

function Pesquisa() {
    const [ itemPesquisado, setItemPesquisado ] = useState([])

    //console.log(itemPesquisado)

    return (
        <section className="section">
            <h2>Pesquisa</h2>
            <h3>Encontre seu roteador.</h3>
            <input className="input"
                placeholder="Nome do router ou peer"
                onBlur={evento => {
                    const textoDigitado = evento.target.value
                    const resultadoPesquisa = routers.filter( router => router.name.includes(textoDigitado))
                    setItemPesquisado(resultadoPesquisa)
                }}
            />
            { itemPesquisado.map( item => (
                <p>Item Encontrado: {item.name}</p>
            )) }
        </section>
    )
}

export default Pesquisa
