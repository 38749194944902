import React from "react";
import "./MyRouters.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import Navbar from "./Navbar";
import ViewButton from "./ButtonView";
import { routers } from '../dados/routers'


const MyRouters = () => {
  const navigate = useNavigate();

  const routerData = routers;

  return (    
    <div className="my-routers">
      <Navbar /> 
      <div className="header-buttons">
        <button onClick={() => navigate('/my-routers/add')} className="btn blue-btn">
          <strong>+ Add</strong> Router</button>
        <button className="btn green-btn">Export Router list</button>
      </div>

      <table className="olt-table">
        <thead>
          <tr>
            <th>View</th>
            <th>ID</th>
            <th>Name</th>
            <th>Router IP</th>
            <th>Telnet/SSH TCP port</th>
            <th>IPTV</th>
            <th>Router hardware version</th>
            <th>Router FW version</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {routerData.map((router) => (
            <tr key={router.id}>
              <td>
                <ViewButton routerId={router.id} />
              </td>
              <td>{router.id}</td>
              <td>{router.name}</td>
              <td>{router.ip}</td>
              <td>{router.port}</td>
              <td>{router.iptv}</td>
              <td>{router.hardwareVersion}</td>
              <td>{router.softwareVersion}</td>
              <td>
                <button className="btn gray-btn small-btn">
                  <FontAwesomeIcon icon={faEyeSlash} />
                </button>
                <button className="btn red-btn small-btn">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                <button className="btn blue-btn small-btn">
                  {">ping"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MyRouters;
