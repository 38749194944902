import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./ButtonView.css"

const ViewButton = ({ routerId }) => {
  const navigate = useNavigate();

  const handleViewClick = () => {
    navigate(`/my-routers/view?id=${routerId}`);
  };

  return (
    <button className="btn blue-btn small-btn" onClick={handleViewClick}>
      View
    </button>
  );
};

export default ViewButton;
