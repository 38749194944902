import React from 'react';
import './AddRouter.css';
import "./Navbar.css";
import Navbar from "./Navbar";

const AddRouter = () => {
  return (
    <div>
      <Navbar />
      <div className="add-router-container">
        <h1>Add New Router</h1>
        <form className="add-router-form">
          <div className="form-group">
            <label>Name</label>
            <input type="text" placeholder="Enter name" />
          </div>
          <div className="form-group">
            <label>Router IP or FQDN</label>
            <input type="text" placeholder="Enter IP or FQDN" />
          </div>
          <div className="form-group">
            <label>Telnet TCP port</label>
            <input type="number" placeholder="Enter port" />
          </div>
          <div className="form-group">
            <label>Router Telnet Username</label>
            <input type="text" placeholder="Enter username" />
          </div>
          <div className="form-group">
            <label>Router Telnet Password</label>
            <input type="password" placeholder="Enter password" />
          </div>
          <div className="form-group">
            <label>SNMP Read-Only Community</label>
            <input type="text" placeholder="Generated automatically" readOnly />
          </div>
          <div className="form-group">
            <label>SNMP Read-Write Community</label>
            <input type="text" placeholder="Generated automatically" readOnly />
          </div>
          <div className="form-group">
            <label>SNMP UDP Port</label>
            <input type="number" placeholder="Enter SNMP UDP port" />
          </div>
          <div className="form-group">
            <label>IPTV Module</label>
            <input type="checkbox" /> Enable
          </div>
          <div className="form-group">
            <label>Router Hardware Version</label>
            <select>
              <option>Huawei-NE8000-F1A</option>
              <option>Huawei-NE8000-M8</option>
              <option>Juniper-MX150</option>
              <option>Juniper-MX204</option>
              <option>Cisco-ASR1002</option>
              <option>Mikrotik-CCR1036</option>
            </select>
          </div>
          <div className="form-group">
            <label>OLT Software Version</label>
            <select>
              <option>R008</option>
              <option>R021</option>
            </select>
          </div>
          <div className="form-group">
            <label>Supported PON Types</label>
            <div className="pon-types">
              <label><input type="radio" name="pon-type" value="gpon" /> GPON</label>
              <label><input type="radio" name="pon-type" value="epon" /> EPON</label>
              <label><input type="radio" name="pon-type" value="both" /> GPON+EPON</label>
            </div>
          </div>
          <div className="form-actions">
            <button type="submit" className="btn btn-save">Save</button>
            <button type="button" className="btn btn-cancel">Cancel</button>
            <button type="button" className="btn btn-test">Test Connection</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddRouter;
