import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashBoard from "./components/DashBoard"; // Página principal
import MyRouters from "./components/MyRouters"; // Página MyRouters
import MyPeers from "./components/MyPeers"; // Página MyPeers
import PeersOffline from "./components/PeersOffline"; // Página PeersOffline
import SessionsFlapping from "./components/SessionsFlapping"; // Página SessionsFlapping
import AddRouter from "./components/AddRouter"; // Página AddRouter
import TroubleShoot from "./components/TroubleShoot"; // Página TS
import Autenticar from "./components/Autenticar"; // Mecanismo de autenticacao
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login"; // Página Login
import Logout from "./components/Logout"; // Mecanismo de Logout
import RouterView from "./components/RouterView";
import "./App.css";



function App() {
  return (
    <Router>
      <Routes>
      <Route 
          path="/" 
          element={
            <ProtectedRoute>
              <DashBoard /> 
            </ProtectedRoute>
          }
        />
        <Route 
          path="/my-routers" 
          element={
            <ProtectedRoute>
              <MyRouters /> 
            </ProtectedRoute>
          }
        />
        <Route 
          path="/my-routers/view" 
          element={
            <ProtectedRoute>
              <RouterView />            
            </ProtectedRoute>
          }
        />
        <Route 
          path="/my-peers" 
          element={
            <ProtectedRoute>
              <MyPeers /> 
            </ProtectedRoute>
          }
        />
        <Route 
          path="/my-routers/add" 
          element={
            <ProtectedRoute>
              <AddRouter /> 
            </ProtectedRoute>
          }
        />
        <Route 
          path="/my-peers" 
          element={
            <ProtectedRoute>
              <MyPeers /> 
            </ProtectedRoute>
          }
        />
        <Route 
          path="/peers-offline" 
          element={
            <ProtectedRoute>
              <PeersOffline /> 
            </ProtectedRoute>
          }
        />
        <Route 
          path="/ts" 
          element={
            <ProtectedRoute>
              <TroubleShoot />            
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/autenticar" element={<Autenticar />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </Router>
  );
  
 
}

export default App;