import React from "react";
import Navbar from "./Navbar";
import StatusCards from "./StatusCards";
import Graphs from "./Graphs";
import RouterList from "./RouterList";
import InfoPanel from "./InfoPanel";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MyRouters from "./MyRouters"; // Página MyRouters
import "./DashBoard.css";


function DashBoard() {
    return (
        // Div principal
        <div className="App">
          <Navbar /> 
          <div className="alert-bar">
            Essa é uma versão BETA do sistema BGPeer
            <strong> bgpeer.com.br</strong>, your Smarter BGP Peer provider.
          </div>
          <StatusCards />
          <div className="main-content">
            <div className="left-section">
              <Graphs />
            </div>
            <div className="right-section">
              <RouterList />
              <InfoPanel />
            </div>
          </div> 
        </div> // Div principal
      );
    }

export default DashBoard;
