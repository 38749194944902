import React from "react";
import Navbar from "./Navbar";


const MyPeers = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div style={{ padding: "20px" }}>
        <h1>My Peers</h1>
        <p>Bem-vindo à página de Peers!</p>
      </div>
    </div>
  );
};

export default MyPeers;