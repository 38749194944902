import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import "./StatusCards.css";
import { useNavigate } from "react-router-dom";

function StatusCards() {
  const navigate = useNavigate();

  const cards = [
    { label: "My Routers", value: 0, color: "blue", icon: faClock, onClick: () => navigate("/my-routers")},
    { label: "My Peers", value: 1785, color: "green", icon: faCheckCircle, onClick: () => navigate("/my-peers") },
    { label: "Peers offline", value: 97, color: "red", icon: faTimesCircle, onClick: () => navigate("/peers-offline") },
    { label: "TroubleShoot", value: 106, color: "orange", icon: faExclamationTriangle, onClick: () => navigate("/ts") },
  ];

  return (
    <div className="status-cards">
      {cards.map((card, index) => (
        <div key={index} className={`card ${card.color}`} style={{cursor: "pointer"}} onClick={card.onClick} >
          <FontAwesomeIcon icon={card.icon} className="card-icon" />
          <h3>{card.label}</h3>
          <p>{card.value}</p>
        </div>
      ))}
    </div>
  );
}

export default StatusCards;
