import React from 'react';
import './RouterView.css';
import Navbar from './Navbar';
import routerIMG from "../img/huawei-ne8000f1a.webp";
import { routers } from '../dados/routers'
import { useSearchParams } from 'react-router-dom';

const RouterView = () => {
  const [searchParams] = useSearchParams();
  const routerId = Number(searchParams.get('id')); // Obtém o ID do roteador
  
  const router = routers.find((router) => router.id === routerId) || {}; // Filtra pelo campo 'id'

  return (
    <div className="router-view">
      <Navbar />
      <button className="back-button">Back to Router List</button>
      <div className="router-header">
        <h2>Router Details</h2>
        <img src={routerIMG} alt="Router" className='router-image'></img>
        <div className="uptime">
          <p>Uptime: {router.uptime}</p>
        </div>
      </div>
      <div className="router-details">
        <table>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{router.name}</td>
            </tr>
            <tr>
              <td>Router IP</td>
              <td>{router.ip}</td>
            </tr>
            <tr>
              <td>Reachable via VPN</td>
              <td>{router.vpnReachable}</td>
            </tr>
            <tr>
              <td>Telnet TCP Port</td>
              <td>{router.telnetPort}</td>
            </tr>
            <tr>
              <td>Telnet Username</td>
              <td>{router.username}</td>
            </tr>
            <tr>
              <td>Telnet Password</td>
              <td>{router.password}</td>
            </tr>
            <tr>
              <td>SNMP Read-Only Community</td>
              <td>{router.snmpReadOnly}</td>
            </tr>
            <tr>
              <td>SNMP Read-Write Community</td>
              <td>{router.snmpReadWrite}</td>
            </tr>
            <tr>
              <td>SNMP UDP Port</td>
              <td>{router.snmpUdpPort}</td>
            </tr>
            <tr>
              <td>IPTV Module</td>
              <td>{router.iptvModule}</td>
            </tr>
            <tr>
              <td>Hardware Version</td>
              <td>{router.hardwareVersion}</td>
            </tr>
            <tr>
              <td>Software Version</td>
              <td>{router.softwareVersion}</td>
            </tr>
            <tr>
              <td>Supported PON Types</td>
              <td>{router.ponTypes}</td>
            </tr>
            <tr>
              <td>TR069 Profile</td>
              <td>
                <select>
                  <option value="SmartOLT">
                    {router.tr069Profile}
                  </option>
                  {/* Adicione mais opções aqui */}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RouterView;
