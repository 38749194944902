import React, { useState } from "react";
import "./Login.css";
import autenticar from "./Autenticar"; // Mecanismo de autenticacao
import { useNavigate } from "react-router-dom";


const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const response = await autenticar(email, password);
        if (response.ok) {
            const data = await response.json();
            localStorage.setItem("token", data.token);
            navigate("/"); //Redireciona para pagina incial
        } else {
            setErrorMessage("Usuário e senha incorreto!");
            //setTimeout(() => setErrorMessage(""), 5000);
        }
    } catch (error) {
        if (error.message === "Failed to fetch") {
            setErrorMessage("Erro ao conectar ao servidor. Verifique se o backend está ativo.");
        } else {
            console.error("Erro no login:", error);
            setErrorMessage("Ocorreu um erro inesperado. Possível erro de conexão com o servidor.");
        }
        //setTimeout(() => setErrorMessage(""), 5000);
    }
};

  return (
    <div class="login-container">
        {errorMessage && (
            <div className="error-banner">
                <span>{errorMessage}</span>
                <button className="close-btn" onClick={() => setErrorMessage(null)}>
                    &times;
                </button>
            </div>)}
        <div class="login-box">
            <h1>BGP<span>eer</span></h1>
            <form onSubmit={handleLogin}>
                <input 
                    type="email" 
                    placeholder="Email"  
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                    required />
                <input 
                    type="password" 
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required />
                <div class="remember-me">
                    <label>
                        <input type="checkbox" /> Lembrar de mim
                    </label>
                </div>
                <button type="submit">Login</button>
            </form>
            <a href="#">Esqueceu a senha?</a>
        </div>
    </div>
  );
};

export default Login;
