import React from "react";
import "./Navbar.css";
import logo from "../img/logo-transparente.png";
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <nav className="navbar">
      <div className="logo">
        <a onClick={() => navigate('/')}>
          <img src={logo} alt="Logo" className='navbar-img'></img></a>
        <a onClick={() => navigate('/')}>BGPeer</a>
        
      </div>
      <div className="menu">
        <span onClick={() => navigate('/my-routers')}>Routers</span>
        <span onClick={() => navigate('/my-peers')}>Peers</span>
        <span onClick={() => navigate('/')}>Graphs</span>
        <span onClick={() => navigate('/ts')}>Troubleshoot</span>
        <span onClick={() => navigate('/')}>Reports</span>
        <span onClick={() => navigate('/')}>Save Config</span>
        <span onClick={() => navigate('/')}>Settings</span>
      </div>
      <div className="logout">
        <span onClick={() => navigate('/logout')}>Logout</span>
      </div>
    </nav>
  );
}

export default Navbar;
