import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import "./RouterList.css"; // Certifique-se de que o CSS está importado corretamente

function RouterList() {
  return (
    <div className="olt-list-container">
      <div className="olt-card">
        <div className="card-header">
          <h3>Routers</h3>
          <FontAwesomeIcon icon={faNetworkWired} className="icon" />
        </div>
        <p>42 Routers</p>
      </div>
    </div>
  );
}

export default RouterList;
