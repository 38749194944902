import React from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Graphs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons"; // Ícone de gráfico

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Graphs() {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          },
        },
      };

  const lineData = {
    labels: ["20:00", "00:00", "04:00", "08:00", "12:00", "16:00"],
    datasets: [
      {
        label: "Daily Network Status",
        data: [1068, 1070, 1065, 1076, 1074, 1068],
        borderColor: "#2196f3", // Alterando para azul
        //backgroundColor:  "rgba(33, 150, 243, 0.2)", // Fundo suave
        fill: 'start', // Preencher abaixo da linha a partir do eixo X
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Cor de preenchimento abaixo da linha
        borderWidth: 2, // Aumentando a espessura da linha
        pointRadius: 3, // Tamanho dos pontos
        pointBackgroundColor: "#2196f3",
        tension: 0.3,
      },
    ],
  };
  
  const barData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "ONU Authorizations per Day",
        data: [2, 4, 5, 3, 5, 6, 2],
        backgroundColor: "#ff9800", // Cor do gráfico de barras
        borderRadius: 5, // Bordas arredondadas nas barras
      },
    ],
  };

  return (
    <div className="graph-container">
      <div className="graph">
        <h3>
            <FontAwesomeIcon icon={faChartLine} className="graph-icon" />
            Daily Peers Network Status
        </h3>
        <Line data={lineData} options={options}/>
      </div>
      <div className="graph">
        <h3>
            <FontAwesomeIcon icon={faChartLine} className="graph-icon" />
            Peers Created per Day
        </h3>
        <Bar data={barData} />
      </div>
    </div>
  );
}

export default Graphs;
