const API = process.env.REACT_APP_API_BACK;


export const Autenticar = async (email, password) => {
    return await fetch(`${API}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body: JSON.stringify({ email, password }),
    });

};

export default Autenticar;
