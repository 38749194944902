import React from "react";
import axios from "axios";
import "./TroubleShoot.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import Navbar from "./Navbar";
import Pesquisa from "./Pesquisa";
import { routers } from '../dados/routers'
import ViewButton from "./ButtonView";

const API = process.env.REACT_APP_API_BACK;

const TS = () => {
  const navigate = useNavigate();
  const handlePing = async (ip) => {
    alert(`Confirma a execução de ping em: ${ip}`);
    try {
      const response = await axios.post(`${API}/ping-rafael`, { ip });
      alert(`Resultado do ping:\n${response.data.output}`);
    } catch (error) {
      alert(`Erro ao executar o ping: ${error.response?.data?.error || error.message}`);
    }
  }

  const handlePrintBGP = async (ip) => {
    alert(`Confirma a execução de show bgp peers em: ${ip}`);
    try {
      const response = await axios.post(`${API}/bgp-print-bgp51`, { ip });
      alert(`Resultado do comando:\n${response.data.output}`);
    } catch (error) {
      alert(`Erro ao executar o comando: ${error.response?.data?.error || error.message}`);
    }
  }

  const routerData = routers;
  

  return (    
    <div className="ts">
      <Navbar />
      <div className="Pesquisa">
        <Pesquisa />
      </div>
      <table className="olt-table">
        <thead>
          <tr>
            <th>View</th>
            <th>ID</th>
            <th>Name</th>
            <th>Router IP</th>
            <th>Telnet/SSH TCP port</th>
            <th>IPTV</th>
            <th>Router hardware version</th>
            <th>Router FW version</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {routerData.map((router) => (
            <tr key={router.id}>
              <td>
                <ViewButton routerId={router.id} />
              </td>
              <td>{router.id}</td>
              <td>{router.name}</td>
              <td>{router.ip}</td>
              <td>{router.port}</td>
              <td>{router.iptv}</td>
              <td>{router.hardwareVersion}</td>
              <td>{router.softwareVersion}</td>
              <td>
                <button className="btn gray-btn small-btn">
                  <FontAwesomeIcon icon={faEyeSlash} />
                </button>
                <button className="btn red-btn small-btn">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                <button className="btn blue-btn small-btn" onClick={() => handlePing(router.ip)}>
                  {">ping"}
                </button>
                <button className="btn blue-btn small-btn" onClick={() => handlePrintBGP(router.ip)}>
                  {">show bgp peers"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TS;
