import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"; // Ícone Info
import "./InfoPanel.css"; // Certifique-se de que o CSS esteja importado corretamente

function InfoPanel() {
  return (
    <div className="info-panel-container">
      <div className="info-card">
        <div className="card-header">
          <h3>Info</h3>
          <FontAwesomeIcon icon={faInfoCircle} className="icon" />
        </div>
        <p>23 Peers Active</p>
      </div>
    </div>
  );
}

export default InfoPanel;
