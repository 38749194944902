import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";



const Logout = () => {
    const navigate = useNavigate(); 
      
    //alert("Logout solicitado!");

    useEffect(() => {
        const handleLogout = () => {
          try {
            const token = localStorage.getItem("token");
            if (token) {
              localStorage.removeItem("token");
              console.log("Logout bem-sucedido!");
            } else {
                console.log("Nenhum token encontrado.");
            }
            navigate("/login"); // Redireciona para a página inicial
          } catch (error) {
            console.error("Erro no logout:", error);
          }
        };
    
        handleLogout();
      }, [navigate]);
    
      return null; // Não precisa renderizar nada
    };
    
    export default Logout;
